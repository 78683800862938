import { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import counsalrImage from "../../assets/images/counselarNew.png";
import "./counsellorScreen.css";
import { GetAllCouncelors } from "../../store/interface";
import { GetCouncellors } from "../../api/councellor";
import Swal from "sweetalert2";
import Loader from "../../components/loader/Loader";
import { AxiosError } from "axios";

const initialCouncellors: GetAllCouncelors = {
  statusCode: 200,
  data: [
    {
      id: 0,
      counsellorId: "",
      name: "",
      organisationId: 0,
      isPaid: false,
      feePerSession: 0,
      expertise: "",
      bio: "",
      experience: 0,
    },
  ],
  message: "",
  isSuccessful: false,
};

const CounselorScreen = () => {
  const [councellorsdata, setCouncellorsdata] =
    useState<GetAllCouncelors>(initialCouncellors);
  const maxRating = 5;

  const [errors, setErrors] = useState<string | null>(null);
  const [loader, setLoader] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [experience, setExperience] = useState<number | "">("");

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const filteredCounsellors = councellorsdata.data.filter(
    (counsellor) => {
      const matchesName = counsellor.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const matchesExperience = experience
        ? counsellor.experience >= experience
        : true; // Adjust this line according to your data structure
      return matchesName && matchesExperience;
    }
    // counsellor.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const fetchCouncellorsdata = async () => {
      try {
        setLoader(true);
        const response = await GetCouncellors();
        setCouncellorsdata(response.data);
        setLoader(false);
      } catch (error) {
        setLoader(false);
        let _errorMsg: string = "";
        if (error instanceof AxiosError) {
          setErrors(error.message);
          _errorMsg = error.message;
        } else {
          setErrors("An unknown error occurred");
          _errorMsg = "Something went wrong! Try after sometime";
        }
        console.error("Error fetching counselor data:", error);
      }
    };

    fetchCouncellorsdata();
  }, []);

  return (
    <div className="disorder-bg-color">
      {loader && <Loader />}
      <Header />
      <div className="container ">
        {/* <h1 className="text-center m-5">Counselor Screen</h1> */}
        <div className="app-header-center">
          <h2>Top Counselor</h2>
        </div>
        {/* <div className="text-end mt-2 mb-5">
          <div className="d-flex justify-content-end ">
            <div className="Search-input-weight border border-2 p-1  border-primary bg-light rounded-4 ">
              <div className="disorder-bg-color bg-primary">
                <div className="d-flex justify-content-end bg-light">
                  <input
                    type="text"
                    className="Search-input   bg-light"
                    placeholder="Search by name or ID..."
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <span
                    className="input-group-text bg-light  " // Adding click handler
                    style={{ cursor: "pointer" }}
                    onClick={handleSearchIconClick}
                  >
                    <i className="bi bi-search "></i>
                  </span>
                  <div className="mx-2">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Min Experience (Years)"
                      value={experience} // Set the value to experience state
                      onChange={(e) => setExperience(Number(e.target.value))} // Update experience state
                    />
                  </div>
                  <div className="mx-2">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Rating"
                      // value={experience} // Set the value to experience state
                      // onChange={(e) => setExperience(Number(e.target.value))} // Update experience state
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/*start test */}

        <div className="row justify-content-end mt-4 mb-5 card-default-padding">
          <div className="col-12 col-md-6 col-lg-6 ">
            {/* col-sm-6 col-xs-12 col-md-3 col-lg-2 */}
            <div className="search-input-weight border border-2 p-1 form-control border-primary bg-light rounded-4">
              <div className="d-flex align-items-center ">
                <input
                  type="text"
                  className="Search-input bg-light "
                  placeholder="Search by name "
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <span
                  className="input-group-text bg-light border-0 "
                  style={{ cursor: "pointer" }}
                >
                  <i className="bi bi-search "></i>
                </span>
              </div>
            </div>
          </div>

          <div className="col-sm-6 col-xs-12  col-md-3 col-lg-2  mt-2 mt-md-0">
            <input
              type="number"
              className="form-control p-2"
              placeholder="Min Experience (Years)"
              // value={experience}
              // onChange={(e) => setExperience(Number(e.target.value))}
            />
          </div>
          {/* col-xs-12 col-sm-6 col-md-4 col-lg-3 */}
          <div className="col-sm-6 col-xs-12 col-md-3 col-lg-2 mt-2 mt-md-0 ">
            <input
              type="number"
              className="form-control p-2"
              placeholder="Rating"
              // value={experience} // Uncomment if you want to bind to state
              // onChange={(e) => setExperience(Number(e.target.value))} // Uncomment if needed
            />
          </div>
          <div className="col-sm-6 col-xs-12 col-md-3 col-lg-2 mt-2 mt-md-0 ">
            <select
              className="form-control border-primary  p-2"
              // onChange={(e) => handleSortChange(e.target.value)}
            >
              <option value="ascending ">
                Sort by name in Ascending order
              </option>
              <option value="descending">
                Sort by name in Descending order
              </option>
            </select>
          </div>
        </div>

        {/*end test */}
        <div className="row justify-content-center">
          {/*  */}
          {/* {councellorsdata.data.map((counsellor) => ( */}

          {councellorsdata.data.length > 0 ? (
            councellorsdata.data.slice(0, 20).map((counsellor) => (
              <div
                key={counsellor.id}
                // d-flex align-items-stretch
                className="col-12  card-default-padding col-sm-6 col-md-4 col-lg-3 mb-4  "
              >
                {/* col-xs-12 col-sm-6 col-md-4 col-lg-3 card-default-padding */}
                <div className="card glass text-center w-100">
                  <img
                    // counsellor.image
                    src={counsalrImage || counsalrImage}
                    alt={counsellor.name}
                    className="counselloScreen_counsellorImg card-img-top"
                  />
                  <div className="card-body">
                    <p className="counselloScreen_counsellorName">
                      {counsellor.name}
                    </p>
                    <button className="counselloScreen_joinButton btn btn-primary btn-sm">
                      <strong className="fw-bold">Join</strong>
                    </button>
                    <div className="counselloScreen_counsellorRating mt-2">
                      <i className="starfill bi bi-star-fill"></i>
                      <i className="starfill bi bi-star-fill"></i>
                      <i className="starfill bi bi-star-fill"></i>
                      <i className="starfill bi bi-star-fill"></i>
                      <i className="starfill bi bi-star-fill"></i>

                      {/* {Array.from({ length: maxRating }, (v, i) => (
                      <i
                        key={i}
                        className={`bi bi-star${i < counsellor.rating ? "-fill starfill" : ""}`}
                      ></i>
                    ))} */}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center text-center mt-4">
              <img
                className="card mb-3 p-1"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyWbZr5X9RcPtNHJbvTo8OhvjJnP6aKijpAQ&s"
                height={"200px"}
                width={"200px"}
                alt="No Results"
              />
              <div className="">No results found</div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CounselorScreen;
