

import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import Pagination from '../../components/pagination/Pagination';


interface Item {
  id: number;
  name: string;
}

const Test: React.FC = () => {
  // Sample list of items (you can replace this with data from an API)
  const items: Item[] = Array.from({ length: 50 }, (_, i) => ({
    id: i + 1,
    name: `Item ${i + 1}`,
  }));

  // Pagination state
  const [currentPage, setCurrentPage] = useState<number>(0);

  // Items per page
  const itemsPerPage = 5;

  // Calculate the current items to display based on currentPage
  const offset = currentPage * itemsPerPage;
  const currentItems = items.slice(offset, offset + itemsPerPage);

  // Handle page change event
  const handlePageChange = (selectedItem: { selected: number }) => {
    debugger;
    setCurrentPage(selectedItem.selected);
  };

  return (
    <div>
      <h2>Paginated List</h2>
      <ul>
        {currentItems.map(item => (
          <li key={item.id}>{item.name}</li>
        ))}
      </ul>

      <Pagination pageCount={10} handlePageChange={handlePageChange}/>

      
    </div>
  );
};

export default Test;
