import React, { useState } from 'react';
import { SelectOption } from '../../store/interface';
import "./Select.css"

interface SelectComponentProps {
  options: SelectOption[];
  showLabel:boolean;
  className:any;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>, extraData: any) => void;
  extraData: any;
}

const Select: React.FC<SelectComponentProps> = ({ options,showLabel,className,onChange,extraData}) => {
  //const [selectedOption, setSelectedOption] = useState<string>(options[0]?.value || '');
  
  className= "select-style "+className

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event, extraData); // Pass both event and extra data to the custom onChange function
  };

  return (
    <div className={className}>
      {showLabel && <label htmlFor="select">Choose an option: </label>}
      <select id="select"  onChange={handleChange}>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
