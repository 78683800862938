// import React from 'react';
// import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import Login from '../pages/Login';
// import Signup from '../pages/Signup';
// import Home from '../pages/Home';
// import PrivateRoute from './PrivateRoute';

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <PrivateRoute><Home /></PrivateRoute>,
//   },
//   {
//     path: '/login',
//     element: <Login />,
//   },
//   {
//     path: '/signup',
//     element: <Signup />,
//   },
//   {
//     path: '*',
//     element: <Login />, // Default route or 404 handler
//   },
// ]);

// const AppRouter: React.FC = () => <RouterProvider router={router} />;

// export default AppRouter;

import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import Homeold from "../pages/Homeold";
import NotFound from "../pages/NotFound";
import { useSelector } from "react-redux";
import { RootState } from "../store/index";
import SignupOTP from "../pages/SignupOTP";
import Registration from "../pages/Registration";
import MentalDisorder from "../pages/MentalDisorder";
import LeaderBoard from "../pages/LeaderBoard";
import NationalStanding from "../pages/NationalStanding";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import Home from "../pages/home/Home";
import ZonalStanding from "../pages/leaderBoardScreen/zonalStanding/ZonalStanding";
import LeaderBoardScreennew from "../pages/leaderBoardScreen/LeaderBoardScreennew";
import MentalDisorders from "../pages/mentalDisorders/MentalDisorders";
import NationalStandingNew from "../pages/leaderBoardScreen/nationalStandingNew/NationalStandingNew";
import InstitutionalStanding from "../pages/leaderBoardScreen/institutionalStanding/InstitutionalStanding";
import CounsellorScreen from "../pages/counsellorScreen/CounsellorScreen";
import CounsellorInformation from "../pages/counsellorScreen/counsellorInformation/CounsellorInformation";
import GuardianPortal from "../pages/guardianPortal/GuardianPortal";
import GuardianAngel from "../pages/guardianPortal/guardianAngel/GuardianAngel";
import Sos from "../pages/guardianPortal/sos/Sos";
import TermsAndConditions from "../pages/termsAndConditions/TermsAndConditions";
import InstitutePortal from "../pages/institutePortal/InstitutePortal";
import MentalHealth from "../pages/guardianPortal/mentalHealth/MentalHealth";
import SleepPattern from "../pages/guardianPortal/sleepPattern/SleepPattern";
import BMI from "../pages/guardianPortal/bmi/BMI";
import PhysicalHealth from "../pages/guardianPortal/physicalHealth/PhysicalHealth";
import InstituteHero from "../pages/institutePortal/instituteHero/InstituteHero";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import DeleteAccount from "../pages/deleteAccount/DeleteAccount";
import Test from "../pages/test/Test";
import RefundPolicy from "../pages/refundPolicy/RefundPolicy";
import CancellationPolicy from "../pages/cancellationPolicy/CancellationPolicy";

const App: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/mentalDisorder" element={<MentalDisorder />} />
        <Route path="/mentalDisorders" element={<MentalDisorders />} />
        <Route path="/leaderBoard" element={<LeaderBoard />} />
        <Route path="/zonalStanding" element={<ZonalStanding />} />
        <Route
          path="/nationalstanding"
          element={<NationalStandingNew />}
        ></Route>
        {/* <Route path="/nationalStanding" element={<NationalStanding />} /> */}
        <Route
          path="/LeaderBoardScreennew"
          element={<LeaderBoardScreennew />}
        />
        <Route
          path="/institutionalStanding"
          element={<InstitutionalStanding />}
        />
        {/* <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} /> */}
        <Route path="/" element={user ? <Home /> : <Navigate to="/login" />} />
        <Route
          path="/home"
          element={user ? <Home /> : <Navigate to="/login" />}
        />
        <Route path="/header" element={<Header />}></Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/footer" element={<Footer />}></Route>
        <Route path="/counsellorscreen" element={<CounsellorScreen />}></Route>
        <Route
          path="/counsellorInformation"
          element={<CounsellorInformation />}
        ></Route>

        <Route path="/guardianPortal" element={<GuardianPortal />}></Route>
        <Route path="/guardianangel" element={<GuardianAngel />}></Route>
        <Route path="/emergency" element={<Sos />}></Route>
        <Route path="/institutePortal" element={<InstitutePortal />}></Route>
        <Route path="/instituteHero" element={<InstituteHero />}></Route>

        <Route path="/mentalHealth" element={<MentalHealth />}></Route>
        <Route path="/sleepPattern" element={<SleepPattern />}></Route>
        <Route path="/physicalHealth" element={<PhysicalHealth />}></Route>
        <Route path="/bMI" element={<BMI />}></Route>

        <Route
          path="/termsAndConditions"
          element={<TermsAndConditions />}
        ></Route>

        <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/refundPolicy" element={<RefundPolicy />}></Route>
        <Route path="/cancellationPolicy" element={<CancellationPolicy />}></Route>
        <Route path="/deleteAccount" element={<DeleteAccount />}></Route>
        <Route path="/test" element={<Test />}></Route>
      </Routes>
    </Router>
  );
};

export default App;
