import axios from './_dotnetConfiguration';
import { GetAllCouncelors } from '../store/interface';
import {counsellorDetails}  from '../store/interface';

// export const getInstitutionalboard = async() =>{
//     debugger;
//     return await axios.get<InstitutionalResponse>('/Leaderboard/GetInstitutionalRankings');
//   }

export const  GetCouncellors = async() =>{
    return await axios.get<GetAllCouncelors>('/Councellor/GetAllCouncellors');
}

// export const  GetCouncellors = async() =>{
//     return await axios.get<counsellorDetails>('/Councellor/GetAllCouncellors');
// }