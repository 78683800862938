import React, { useRef, useState, ChangeEvent, useEffect } from "react";
import "./home.css";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import HomeBanner from "../../components/homeBanner/HomeBanner";
import frameF from "../../assets/images/frameF.png";
import frameS from "../../assets/images/frameS.png";
import frameT from "../../assets/images/frameT.png";
import frameFour from "../../assets/images/frameFour.png";
import frameFive from "../../assets/images/frameFive.png";
import frameSix from "../../assets/images/frameSix.png";
import circle from "../../assets/images/circleweb.png";
import counsalrImage from "../../assets/images/counselarNew.png";
import star from "../../assets/images/greenStar.png";
import graphFirst from "../../assets/images/graphFirst.png";
import graphSecond from "../../assets/images/graphSecond.png";
import graphThird from "../../assets/images/graphThird.png";
import graphFour from "../../assets/images/graphFour.png";
import GetinTouchWithUs from "../../components/getInTouchWithUs/GetinTouchWithUs";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import authSaga from "../../store/auth/authSaga";
import { GetAllCouncelors } from "../../store/interface";
import { GetCouncellors } from "../../api/councellor";

const emojiData = [
  { _id: "1", emojiImage: frameF },
  { _id: "2", emojiImage: frameS },
  { _id: "3", emojiImage: frameT },
  { _id: "4", emojiImage: frameFour },
  { _id: "5", emojiImage: frameFive },
  { _id: "6", emojiImage: frameSix },
];

const blockData = [
  {
    id: "1",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",
    name: "Swati Singh",
  },
  {
    id: "2",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",

    name: "priya Singh",
  },
  {
    id: "3",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",
    name: "Swati Singh",
  },
  {
    id: "4",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",
    name: "Swati Singh",
  },
  {
    id: "5",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",
    name: "Swati Singh",
  },
  {
    id: "6",
    block:
      "Great service is attentive, personalized, and proactive, consistently exceeding expectations and building lasting customer loyalty through meaningful, seamless interactions",
    name: "Swati Singh",
  },
];

// const discoveCounsalrData = [
//   {
//     _id: "1",
//     counsalarImage: counsalrImage,
//     counsalrName: "Surajmukhi Jones",
//     counsalrRating: star,
//   },
//   {
//     _id: "2",
//     counsalarImage: counsalrImage,
//     counsalrName: "Sarwan patidar",
//     counsalrRating: star,
//   },
//   {
//     _id: "3",
//     counsalarImage: counsalrImage,
//     counsalrName: "shamshan",
//     counsalrRating: star,
//   },
//   {
//     _id: "4",
//     counsalarImage: counsalrImage,
//     counsalrName: "Surajmukhi Jones",
//     counsalrRating: star,
//   },
//   {
//     _id: "5",
//     counsalarImage: counsalrImage,
//     counsalrName: "Sarwan patidar",
//     counsalrRating: star,
//   },
//   {
//     _id: "6",
//     counsalarImage: counsalrImage,
//     counsalrName: "shamshan",
//     counsalrRating: star,
//   },
// ];

const initialCouncellors: GetAllCouncelors = {
  statusCode: 0,
  data: [],
  message: "",
  isSuccessful: false,
};

const Home: React.FC = () => {
  const [email, setEmail] = useState("");

  const [councellorsdata, setCouncellorsdata] =
    useState<GetAllCouncelors>(initialCouncellors);
  const [visibleUserId, setVisibleUserId] = useState<string | null>(null);

  useEffect(() => {
    const fetchCouncellorsdata = async () => {
      try {
        const response = await GetCouncellors();
        // const sortedData = response.data.sort(
        //   (a: any, b: any) => a.rank - b.rank
        // );
        // setLeaderboardData(sortedData);
        setCouncellorsdata(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    };

    fetchCouncellorsdata();
  }, []);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  // This is for the emoji and how it will be displayed according to the responsiveness of the page.
  var Emoji = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true, // Enables left and right navigation arrows
    pauseOnHover: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },

      {
        breakpoint: 580,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 354,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
        },
      },
      {
        breakpoint: 300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          infinite: true,
        },
      },
    ],
  };

  // This is for the discoveCounsalrData and how it will be displayed according to the responsiveness of the page.

  var settings = {
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true, // Enables left and right navigation arrows
    pauseOnHover: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 6000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1950,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="">
      <HomeBanner />
      <Header />
      {/* i have change container */}
      <div className="container-fluid homeScreencontainer ">
        {/* homeScreencontainerBody start */}
        <div className="homeScreencontainerBody">
          {/* <div className="flex-wrap d-flex ">
            <div className="feelingToday col-lg-8 col-md-8 col-12 rounded ">
              <div className="howAreyou">How are you</div>
              <div className="fellingToday">feeling today ?</div>
              <div className=" container ">
                <Slider {...Emoji}>
                  {emojiData.map((emoji) => (
                    <div key={emoji._id} className="py-5 mt-4  mx-auto">
                      <img
                        src={emoji.emojiImage}
                        alt={`Emoji ${emoji._id}`}
                        className="emoji_new"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="circleImageContainer col-lg-4 col-md-4 col-12  position-relative">
              <img className="circleImage" alt="circle" src={circle} />
            </div>
          </div> */}

          {/* feelingToday Container start */}
          <div className="flex-wrap d-flex ">
            <div className="feelingToday_new col-11 rounded mx-auto">
              <div className="d-flex">
                <div className="col-md-8 col-10 ">
                  <div className="howAreyou">How are you</div>
                  <div className="fellingToday">feeling today ?</div>
                </div>

                <div className="circleImageContainer  col-4 ">
                  <img className="circleImage_new " alt="circle" src={circle} />
                </div>
              </div>

              <div className=" container-fluid emgiContaier ">
                <Slider {...Emoji}>
                  {emojiData.map((emoji) => (
                    <div key={emoji._id} className="py-5 mt-4  mx-auto">
                      <img
                        src={emoji.emojiImage}
                        alt={`Emoji ${emoji._id}`}
                        className="emoji_new"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
          {/* feelingToday Container end */}

          {/* signup and learn more start */}
          <div className="signupLearn row">
            <div className="Signup col-md-6 col-12">
              <button className="Signupbutton">Signup</button>
            </div>
            <div className="LearnMore col-md-6 col-12">
              <button className="LearnMoreButton">Learn More</button>
            </div>
          </div>
          {/*signup and learn more start end */}

          {/*All Members Container  start */}
          <div className="members d-flex flex-wrap  ">
            <div className="col-12  col-md-3 ">
              <button className="membersButton_new">
                <div> 1K+</div>
                <div>Members</div>
              </button>
            </div>

            <div className="col-12 col-md-3  ">
              <button className="membersButton_new">
                <div> 1K+</div>
                <div>Members</div>
              </button>
            </div>

            <div className=" col-12 col-md-3 ">
              <button className="membersButton_new">
                <div> 1K+</div>
                <div>Members</div>
              </button>
            </div>

            <div className="col-12 col-md-3">
              <button className="membersButton_new">
                <div> 1K+</div>
                <div>Members</div>
              </button>
            </div>
          </div>
          {/* All Members Container end */}

          <br></br>
        </div>
        {/* homeScreencontainerBody end */}

        {/* Discover Counsellor and More heading  */}
        <div className="d-flex flex-wrap p-5 my-5 mb-2 ">
          <div className="col-8   col-md-10">
            <div className="discoverCounsellor">Discover Counsellor</div>
          </div>
          <div className="col-4 col-md-2">
            <a href="/counsellorscreen">
              <button className="moreButton">MORE</button>
            </a>
          </div>
        </div>

        {/*All counsellor Start  */}
        <div className="container-fluid slider-container  ">
         { councellorsdata.data && councellorsdata.data.length>0 &&<Slider {...settings}>
            {councellorsdata.data.slice(0, 6).map((counsellor) => (
              <div key={counsellor.id} className="counsellor p-2">
                <img
                  src={counsalrImage}
                  alt={counsellor.name}
                  className="counsellorImg_new"
                />

                <p className="counsellorName_new">{counsellor.name}</p>

                <button className="joinButton_new">Join</button>

                {/* <div className="">
                  <img
                    src={counsellor.counsalrRating}
                    alt={counsellor.counsalrName}
                    className="counsellorRating_new"
                  />
                </div> */}
                <div className="counsellorRating_new">
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                </div>
              </div>
            ))}
          </Slider>}
        </div>
        {/*All counsellor end  */}

        <h2 className="Insight  p-4 ">Insight</h2>
        <div style={{ height: "50px" }}></div>
        {/* All Graph container start   d-flex*/}
        <div className="container-fluid  flex-wrap gap-2 justify-content-center  ">
          <div className="row justify-content-center  gap-2 ">
            <div className="firstGraph graph col-10 mb-2 col-md-5 me-3 mb-3  text-center  border border-4 justify-content-learn border-primary rounded ">
              <img className="graphFirst" src={graphFirst} alt="Insight" />
            </div>
            <div className="SecondGraph  col-10 mb-2 col-md-5 me-3 mb-3  text-center border border-4 border-primary rounded">
              <img className="graphThird" src={graphThird} alt="Insight" />
            </div>
          </div>
          <div className="row gap-2 justify-content-center">
            <div className="ThirdGraph   col-10 mb-2  col-md-5  me-3 mb-3 text-center border border-4 border-primary rounded">
              <img className="graphSecond" src={graphSecond} alt="Insight" />
            </div>
            <div className=" FourGraph col-10 mb-2 col-md-5  me-3 mb-3 text-center border border-4 border-primary rounded">
              <img className="graphFour" src={graphFour} alt="Insight" />
            </div>
          </div>
        </div>
        {/* All Graph container end */}

        <br></br>

        {/* getInTouch start */}
        {/* <div className="getInTouch  d-flex align-items-center justify-content-around mx-auto">
          <h2 className="getInTouchHeader text-white fw-bold  ">
            get in touch with us
          </h2>
          <div className="getInTouchHeaderBody d-flex align-items-center bg-secondary rounded-pill  ">
            <input
              className="getInTouchEmail  flex-grow-1 border-0 rounded-pill ms-1 me-3 p-2"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
            />
            <div className="letsTalkBody d-flex align-items-center justify-content-center bg-white rounded-pill me-1 me-sm-3  px-3 py-2">
              <h2 className="letsTalk text-primary fw-bold  ">Let’s talk</h2>
            </div>
          </div>
        </div> */}

        <div style={{ height: "50px" }}></div>
        <GetinTouchWithUs />
        {/* getInTouch end */}
        {/* Block start */}
        <div style={{ height: "50px" }}></div>
        <div>
          <h2 className="Insight  p-4 ">Block</h2>
        </div>
        <div className="container-fluid slider-container  ">
          <Slider {...settings}>
            {blockData.map((block) => (
              <div key={block.id} className="counsellor p-2">
                <div>
                  <div className=""></div>
                  <p className="">{block.block}</p>
                  <div></div>
                </div>

                <p className="counsellorName_new">{block.name}</p>

                {/* <div className="">
                  <img
                    src={counsellor.counsalrRating}
                    alt={counsellor.counsalrName}
                    className="counsellorRating_new"
                  />
                </div> */}
                <div className="counsellorRating_new">
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                  <i className="starfill bi bi-star-fill"></i>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        {/* Block end */}
      </div>

      <br></br>
      <Footer />
    </div>
  );
};

export default Home;
