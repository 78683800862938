import { call, put, takeLatest } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {signup as signupAPI,verifyEmail as verifyEmailAPI,verifyPhone as verifyPhoneAPI,resendEmailOTP as resendEmailOTP_API,resendPhoneOTP as resendPhoneOTP_API,registration as registrationAPI} from '../../api/registration'
import { getOTP, onGetOtpFailure, onGetOtpSuccess, onRegisterRequestFailure, onRegisterRequestSuccess, onVerifyEmailFailure, onVerifyEmailSuccess, onVerifyPhoneFailure, onVerifyPhoneSuccess, registerRequest, resendEmailOTP, resendPhoneOTP, setSignUp, verifyEmail, verifyPhone } from './registrationSlice';
import { SignupModel } from '../interface';
import Swal from 'sweetalert2';

function* handleSignup(action: ReturnType<typeof getOTP>) {
  try {
    const { data }=yield call(signupAPI,action.payload.reqData);
    debugger;
    yield Swal.fire({
      text: data?.message??"Something went wrong! Try after sometime",
      icon: "success",
      position:"top"
    });
    yield put(onGetOtpSuccess());
  } catch (error:any) {
    debugger;
    yield Swal.fire({
      title: "Error",
      text: error?.response?.data?.message??"Something went wrong! Try after sometime",
      icon: "error",
      position:"top"
    });
    yield put(onGetOtpFailure());
  }
}

function* handleVerifyEmail(action: ReturnType<typeof verifyEmail>) {
  try {
    debugger;
    const { data }=yield call(verifyEmailAPI,action.payload.reqData);
    yield put(onVerifyEmailSuccess());
  } catch (error:any) {
    debugger;
    yield Swal.fire({
      title: "Error",
      text: error?.response?.data?.message??"Something went wrong! Try after sometime",
      icon: "error",
      position:"top"
    });
    yield put(onVerifyEmailFailure());
  }
}

function* handleVerifyPhone(action: ReturnType<typeof verifyPhone>) {
  try {
    debugger;
    const { data }=yield call(verifyPhoneAPI,action.payload.reqData);
    yield put(onVerifyPhoneSuccess());
  } catch (error:any) {
    debugger;
    yield Swal.fire({
      title: "Error",
      text: error?.response?.data?.message??"Something went wrong! Try after sometime",
      icon: "error",
      position:"top"
    });
    yield put(onVerifyPhoneFailure());
  }
}

function* handleResendEmailOTP(action: ReturnType<typeof verifyEmail>) {
  try {
    const { data }=yield call(resendEmailOTP_API,action.payload.reqData);
    debugger;
    yield Swal.fire({
      text: data?.message??"Something went wrong! Try after sometime",
      icon: "success",
      position:"top"
    });
  } catch (error:any) {
    debugger;
    yield Swal.fire({
      title: "Error",
      text: error?.response?.data?.message??"Something went wrong! Try after sometime",
      icon: "error",
      position:"top"
    });
  }
}

function* handleResendPhoneOTP(action: ReturnType<typeof verifyPhone>) {
  try {
    const { data }=yield call(resendPhoneOTP_API,action.payload.reqData);
    debugger;
    yield Swal.fire({
      text: data?.message??"Something went wrong! Try after sometime",
      icon: "success",
      position:"top"
    });
  } catch (error:any) {
    debugger;
    yield Swal.fire({
      title: "Error",
      text: error?.response?.data?.message??"Something went wrong! Try after sometime",
      icon: "error",
      position:"top"
    });
  }
}

function* handleRegistration(action: ReturnType<typeof registerRequest>) {
  try {
    const { data }=yield call(registrationAPI,action.payload.reqData);
    debugger;
    yield put(onRegisterRequestSuccess());
    yield Swal.fire({
      text: data?.message??"Something went wrong! Try after sometime",
      icon: "success",
      position:"top"
    });
    action.payload.navigate('/login');
  } catch (error:any) {
    debugger;
    yield Swal.fire({
      title: "Error",
      text: error?.response?.data?.message??"Something went wrong! Try after sometime",
      icon: "error",
      position:"top"
    });
    yield put(onRegisterRequestFailure());
  }
}

export default function* registrationSaga() {
  yield takeLatest(getOTP.type, handleSignup);
  yield takeLatest(verifyEmail.type,handleVerifyEmail);
  yield takeLatest(verifyPhone.type,handleVerifyPhone);
  yield takeLatest(resendEmailOTP.type,handleResendEmailOTP);
  yield takeLatest(resendPhoneOTP.type,handleResendPhoneOTP);
  yield takeLatest(registerRequest.type,handleRegistration)
}